import React, { useState,useEffect } from 'react'
import PersonList from './components/PersonList';
import AddUserForm from './components/AddUserForm';
import EditUserForm from './components/EditUserForm';
import API from './api';
function App() {
  	// Data
	/*const usersData = [
		{ id: 1, name: 'Tania', username: 'floppydiskette' },
		{ id: 2, name: 'Craig', username: 'siliconeidolon' },
		{ id: 3, name: 'Ben', username: 'benisphere' },
	]*/
  const usersData = []

	const initialFormState = { id: null, name: '', username: '' }
	// Setting state
	const [ users, setUsers ] = useState(usersData)
	const [ currentUser, setCurrentUser ] = useState(initialFormState)
	const [ editing, setEditing ] = useState(false)

	// CRUD operations
	const addUser = user => {
		/*user.id = users.length + 1
		setUsers([ ...users, user ])*/
      console.log('register users',users);
      API.post(`/register`,user)
    .then(res => {
      const data = res.data;
      console.log('register data',data);
      if(data.success==='true'){
        setUsers(data.users)
      }
      
    })
	}

	const deleteUser = id => {
		setEditing(false)

		setUsers(users.filter(user => user.id !== id))
	}

	const updateUser = (id, updatedUser) => {
		setEditing(false)

		setUsers(users.map(user => (user.id === id ? updatedUser : user)))
	}

	const editRow = user => {
		setEditing(true)

		setCurrentUser({ id: user.id, name: user.name, username: user.username })
	}
  useEffect(
    () => {
      console.log('users',users);
      API.get(`users`)
    .then(res => {
      const data = res.data;
      console.log('data',data);
      if(data.success==='true'){
        setUsers(data.users)
      }
      
    })
    },
    []
  )

  return (
    <div ClassName="App">
      {editing ? (
      <>
        <h2>Edit user</h2>
        <EditUserForm
          editing={editing}
          setEditing={setEditing}
          currentUser={currentUser}
          updateUser={updateUser}
        />
      </>
    ) : (
      <>
        <h2>Add user</h2>
        <AddUserForm addUser={addUser} initialFormState={initialFormState}/>
      </>
    )}
      <PersonList users={users} editRow={editRow} deleteUser={deleteUser}/>
    </div>
  )
}
export default App;
/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}*/

